import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'layout-clean',
	templateUrl: './clean.component.html',
	styleUrls: ['./clean.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterOutlet]
})
export class CleanComponent implements OnInit {
	
	constructor(
	) {
	}
		
	ngOnInit() {
	}
	
}
	